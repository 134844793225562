import { ButtonGroup, Grid, Typography } from '@material-ui/core';
import { css } from 'aphrodite';
import format from 'date-fns/format';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { postRejectedMessage } from '../../../helpers/apiHelper';
import { useAcceptReport } from '../../../hooks/useAcceptReport';
import { useRejectReport } from '../../../hooks/useRejectReport';
import { useRole } from '../../../hooks/useRole';
import { ReportStatus, SiteTab } from '../../../interfaces/enums';
import { Tree, TreeScannerDataStatus } from '../../../interfaces/tree';
import { textColors } from '../../../styles/colors';
import { fontsWeights } from '../../../styles/variables';
import { defaultLang, useCurrentLanguage } from '../../../translations/languageSelector';
import AppButton from '../../treeDetailPageNew/components/Form/AppButton/AppButton';
import { NoDataTab } from '../../treeDetailPageNew/components/NoDataTab/NoDataTab';
import { useTreeDetailState } from '../../treeDetailPageNew/TreeDetailProvider/TreeDetailStateProvider';
import { TreeDetailActions } from '../../treeDetailPageNew/TreeDetailProvider/types';
import { styles } from './CalculationsTab.styles';
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';
import { RejectReasonDialog } from './RejectReasonDialog/RejectReasonDialog';
import { generateReportUrl } from '../../../helpers/treeHelpers';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

export const CalculationsTab: FC = () => {
  const activeLang = useCurrentLanguage();
  const loggedUser = useCurrentUser();
  const { activeTree, formDataResponse, loadTree, dispatchTreeDetailState } =
    useTreeDetailState();
  const [open, setOpen] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const {
    mutate: sendRejectedMessage,
    isLoading: sendRejectedMessageIsLoading,
  } = useMutation(postRejectedMessage);

  const { isOperator } = useRole();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirmationDialogOpen = () => {
    setConfirmationDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const methodsReason = useForm({
    defaultValues: {
      rejectedReason: activeTree?.rejectedReason,
    },
  });

  const methodsMessage = useForm({
    defaultValues: {
      rejectedMessage: '',
    },
  });

  useEffect(() => {
    if (formDataResponse) {
      const savedTree = formDataResponse.tree_scanner_data as Tree;
      loadTree(savedTree.id);
    }
  }, [formDataResponse, loadTree]);

  useEffect(() => {
    dispatchTreeDetailState({
      type: TreeDetailActions.SET_CURRENT_TAB,
      // @ts-ignore
      payload: SiteTab.Calculations,
    });
  }, [dispatchTreeDetailState]);

  const { rejectLoading, onReject } = useRejectReport();

  const onSubmit = useCallback(
    async (values: { rejectedReason: string }) => {
      if (activeTree?.id) {
        if (values.rejectedReason === '' && activeTree?.report?.message) {
          values.rejectedReason = activeTree.report.message;
        }
        onReject({
          id: activeTree.id,
          rejectedReason: values.rejectedReason,
        });
      }
    },
    [activeTree, onReject],
  );

  const onSendRejectedMessage = useCallback(
    async (values: { rejectedMessage: string }) => {
      if (activeTree?.id) {
        sendRejectedMessage(
          {
            id: activeTree.id,
            message: values.rejectedMessage,
            status: TreeScannerDataStatus.FAILED,
          },
          {
            onSuccess: () => {
              loadTree(activeTree.id);
              handleConfirmationDialogClose();
            },
          },
        );
      }
    },
    [activeTree, loadTree, sendRejectedMessage],
  );

  const { onAccept, acceptLoading } = useAcceptReport();

  const hasStatus = useMemo(() => {
    const validStatuses = [
      TreeScannerDataStatus.REJECTED,
      TreeScannerDataStatus.READYFORCLIENT,
      TreeScannerDataStatus.FAILED,
    ];
    if (validStatuses.some(status => status === activeTree?.status)) {
      return true;
    }
    return false;
  }, [activeTree?.status]);

  const title = useMemo(() => {
    if (!hasStatus) {
      return activeLang.treeDetailPage.calculations.noStatus;
    }
    return activeTree?.status;
  }, [activeTree, hasStatus, activeLang]);

  const isAcceptDisabled = useMemo(() => {
    if (isOperator) {
      return true;
    }
    if (hasStatus) {
      return true;
    }
    if (acceptLoading) {
      return true;
    }
    return false;
  }, [hasStatus, acceptLoading, isOperator]);

  const isRejectDisabled = useMemo(() => {
    if (isOperator) {
      return true;
    }
    if (hasStatus) {
      return true;
    }
    if (rejectLoading) {
      return true;
    }
    return false;
  }, [hasStatus, rejectLoading, isOperator]);

  const showSendToCustomerButton = useMemo(() => {
    if (
      activeTree?.status === TreeScannerDataStatus.REJECTED ||
      activeTree?.status === TreeScannerDataStatus.FAILED
    ) {
      return false;
    }
    return true;
  }, [activeTree?.status]);

  const columns = [
    {
      name: activeLang.treeDetailPage.calculations.created,
      data:
        activeTree?.report?.created_at &&
        format(new Date(activeTree?.report?.created_at), 'dd.MM.YYY'),
    },
    {
      name: activeLang.treeDetailPage.calculations.status,
      data: activeTree?.report?.status,
    },
  ];

  const downloadFile = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div>
      {activeTree?.report ? (
        <>
          <FormProvider {...methodsMessage}>
            <form
              onSubmit={methodsMessage.handleSubmit(onSendRejectedMessage)}
              id="calculations-reject-message-form"
            >
              <ConfirmationDialog
                isOpen={confirmationDialogOpen}
                handleClose={handleConfirmationDialogClose}
                isLoading={sendRejectedMessageIsLoading}
              />
            </form>
          </FormProvider>
          <FormProvider {...methodsReason}>
            <form
              onSubmit={methodsReason.handleSubmit(onSubmit)}
              id="calculations-reject-reason-form"
            >
              <div className={css(styles.root)}>
                <RejectReasonDialog
                  isOpen={open}
                  handleClose={handleClose}
                  isOperator={isOperator}
                  isRejectDisabled={isRejectDisabled}
                />

                <Grid
                  container
                  justifyContent="space-between"
                  alignContent="center"
                  className={css(styles.header)}
                >
                  <Typography
                    variant="subtitle1"
                    className={css(
                      textColors.textDark,
                      fontsWeights.bold,
                      styles.titleTop,
                    )}
                  >
                    {activeLang.treeDetailPage.calculations.reportTitle}{' '}
                    {activeTree?.report?.id} {' - '} {title}
                  </Typography>
                </Grid>
                <Grid
                  container
                  alignItems="flex-start"
                  className={css(styles.tableWrapper)}
                >
                  <Grid item container xs={12} sm={6}>
                    {columns.map(column => (
                      <Grid item xs={12} md={6} key={column.name}>
                        <Typography className={css(styles.item)}>
                          {column.name}
                        </Typography>
                        <Typography className={css(styles.value)}>
                          {column.data}
                        </Typography>
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={6}>
                      <AppButton
                        disabled={activeTree?.report?.status !== ReportStatus.FINISHED}
                        variant="outlined"
                        className={css(styles.btn)}
                        onClick={() =>
                          downloadFile(generateReportUrl(activeTree!.treeSid!, loggedUser?.language || defaultLang))
                        }
                        type="button"
                      >
                        {activeLang.treeDetailPage.calculations.downloadPDF}
                      </AppButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AppButton
                        disabled={!activeTree?.report?.stlUrl}
                        variant="outlined"
                        className={css(styles.btn)}
                        onClick={() =>
                          downloadFile(activeTree!.report!.stlUrl!)
                        }
                        type="button"
                      >
                        {activeLang.treeDetailPage.calculations.goToStl}
                      </AppButton>
                    </Grid>
                  </Grid>
                  {(activeTree.status === TreeScannerDataStatus.REJECTED ||
                    activeTree.status === TreeScannerDataStatus.FAILED) && (
                      <Grid item xs={12} sm={6}>
                        <Typography className={css(styles.item)}>
                          {activeLang.treeDetailPage.calculations.rejectedReason}
                        </Typography>
                        <Grid>
                          <Typography className={css(styles.message)}>
                            {activeTree.rejectedReason !== ''
                              ? activeTree.rejectedReason
                              : activeTree.report.message}
                          </Typography>
                        </Grid>
                        {activeTree.rejectedMessage !== '' && (
                          <>
                            <Typography
                              className={css(styles.item, styles.rejectedMessage)}
                            >
                              {
                                activeLang.treeDetailPage.calculations
                                  .rejectedMessage
                              }
                            </Typography>
                            <Grid>
                              <Typography className={css(styles.message)}>
                                {activeTree.rejectedMessage}
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    )}
                </Grid>
                {showSendToCustomerButton ? (
                  <ButtonGroup className={css(styles.botBtn)}>
                    <AppButton
                      disabled={isAcceptDisabled}
                      fullWidth
                      color="primary"
                      variant="contained"
                      onClick={onAccept}
                      type="button"
                    >
                      {activeLang.treeDetailPage.calculations.accept}
                    </AppButton>
                    <AppButton
                      disabled={isRejectDisabled}
                      fullWidth
                      className={
                        isRejectDisabled
                          ? css(styles.disabled)
                          : css(styles.rejectButton)
                      }
                      variant="contained"
                      type="button"
                      onClick={handleClickOpen}
                    >
                      {activeLang.treeDetailPage.calculations.reject}
                    </AppButton>
                  </ButtonGroup>
                ) : (
                  <Grid className={css(styles.botBtn)}>
                    <AppButton
                      disabled={
                        activeTree.status === TreeScannerDataStatus.FAILED
                      }
                      variant="contained"
                      type="button"
                      onClick={handleConfirmationDialogOpen}
                    >
                      {activeLang.treeDetailPage.calculations.sendToCustomer}
                    </AppButton>
                  </Grid>
                )}
              </div>
            </form>
          </FormProvider>
        </>
      ) : (
        <NoDataTab title={activeLang.treeDetailPage.calculations.noReport} />
      )}
    </div>
  );
};
