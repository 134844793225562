import { TreeState } from '../interfaces/enums';
import { LanguageKeys } from './keys';

export const cs: LanguageKeys = {
  common: {
    yes: 'ano',
    no: 'ne',
  },
  assigningModal: {
    allOperators: 'Označit všechny operátory',
    cancelButton: 'Zrušit',
    doneButton: ' Dokončit přiřazení',
    title: 'Operátoři',
  },
  confirmEmail: {
    heading: 'Váš email byl úspěšně ověřen.',
  },
  detailProjectPage: {
    analysed: 'Analyzováno',
    analysisLevel: 'Level Analýzy',
    approved: 'Schváleno',
    approvedReport: 'Report',
    backButton: 'Zpět na projekty',
    calculations: 'Výpočty',
    countOfPhotosDetail: 'Počet fotek detailu',
    countOfPhotosTreeTop: 'Počet fotek koruny',
    countOfPhotosTrunk: 'Počet fotek kmene',
    project: 'Projekt',
    rejected: 'Zamítnuto',
    site: 'Plocha',
    taxon: 'Taxon',
    title: 'Přehled stromů',
    tree: 'Strom',
  },
  error: {
    heading: 'Ooops',
    403: 'K této části aplikace nemáte přístup',
    noData: 'Žádná data nejsou k dispozici',
    unknownError: 'Nastala neočekávaná chyba při komunikaci se serverem. Prosím, restartujte aplikaci (F5) a pokud se chyba stane opakovaně, kontaktujte podporu.',
  },
  finished: {
    areaName: 'Plocha',
    dbh: 'DBH',
    finishedOrders: 'Dokončené objednávky',
    groupName: 'Skupina',
    link: 'Link',
    orders: 'Objednávky',
    orderSentError: 'Objednávku se nepodařilo odeslat.',
    orderSentSuccess: 'Objednávka byla úspěšně odeslána.',
    project: 'Projekt',
    report: 'Report',
    safetyFactor: 'Ochranný faktor',
    sendSelected: 'Odeslat vybrané',
    sendWholeOrder: 'Odeslat celou objednávku',
    tagNumber: 'Č. tagu',
    taxon: 'Taxon',
    treeNumber: 'Č. stromu',
  },
  forgotPassword: {
    choosePw: 'Zvolte heslo',
    continue: 'Pokračovat',
    error: 'Něco se pokazilo',
    heading: 'Nastavení nového hesla',
    pwTerm1: 'Minimálně 6 znaků',
    pwTerm2: '1 velké písmeno anebo číslice',
    pwTerms: 'Podmínky hesla:',
    successfull: 'Heslo bylo úspěšně změněno',
  },
  loginPage: {
    button: 'Přihlásit se',
    error: {
      password: 'Zadejte heslo',
      userName: 'Zadejte uživatelské jméno',
      wrong: 'Zadali jste špatné uživatelské jméno a heslo',
    },
    user: 'Uživatel',
    password: 'Heslo',
  },
  notifications: {
    deleteAll: 'Smazat vše',
    Failed: 'Výpočet stromu proběhl neúspěšně.',
    Finished: 'Výpočet stromu je dokončen',
    noNotifications: 'Žádné notifikace',
    Ordered: 'Nový strom čeká na zpracování',
    readAll: 'Přečteno vše',
    title: 'Notifikace',
  },
  rejectNotice: {
    subtext: 'Důvodem pre zamítnutí je:',
    title: 'Analýza neprošla výpočty nebo byla zamítnuta Adminem.',
  },
  summaryOfOperators: {
    analysisOfRoad: 'Silnice',
    analysisOfTreeTop: 'Analýza koruny',
    analysisOfTrunk: 'Analýza kmene',
    countOfDoneTrees: 'Počet stromů',
    name: 'Operátor',
    switch: 'Má povolení',
  },
  summaryOfProjects: {
    buttonAssign: 'Přiřadit',
    countOfPhotos: 'Počet fotek',
    countOfTrees: 'Počet stromů',
    operator: 'Operátor',
    project: 'Projekt',
    stateAnalysisOfRoad: 'Silnice',
    stateAnalysisOfTreeTop: 'Analýza koruny',
    stateAnalysisOfTrunk: 'Analýza kmene',
    stateOfProject: 'Stav',
  },
  summaryPage: {
    headerTitle: 'Přehled stromů',
  },
  tomograms: {
    downloadImage: 'Stáhnout snímek',
    removeImgae: 'smazat snímek',
    showImage: 'Zobrazit snímek',
    showNote: 'Zobrazit poznámku',
    uploadImage: 'nahrát snímek',
  },
  tomogramsPage: {
    header: {
      cuts: 'Řezy',
      generateCuts: 'Vygenerovat řezy',
    },
    inputs: {
      scaleImage: 'Měřítko obrázku',
      scaleReal: 'Reálné měřítko',
    },
  },
  treeDetailPage: {
    backBtn: 'Zpět na přehled stromů',
    buttons: {
      back: 'Zpět',
      reset: 'Reset',
      reset_active_option: 'Reset vybrané části',
    },
    calculations: {
      accept: 'Akceptovat',
      cancel: 'Zrušit',
      confirm: 'Potvrdit',
      created: 'Vytvořeno',
      downloadPDF: 'Stáhnout PDF',
      goToStl: 'Stáhnout 3D Model',
      noReport: 'Žádné zpracované výpočty',
      noStatus: 'Čeká na potvrzení',
      reject: 'Zamítnout',
      rejectedMessage: 'Zpráva zákazníkovi',
      rejectedReason: 'Důvod zamítnutí',
      reportTitle: 'Zpracovaný report č.',
      sendMessage: 'Ano a potvrdit',
      sendToCustomer: 'Odeslat zákazníkovi',
      sendToCustomerConfirmationDialogText1: 'Opravdu chcete odeslat tento zamítnutý report zákazníkovi?',
      sendToCustomerConfirmationDialogText2: 'Zde napište zprávu, která se zobrazí zákazníkovi.',
      sendToCustomerConfirmationDialogTitle: 'Odeslat zamítnutý report',
      status: 'Status',
      title: 'Výpočty',
    },
    formAlert: {
      description: 'Upravte prosím pole',
      message: 'Chyba ve formuláři',
      saved: 'Uloženo',
      savedInfo: 'Analýza úspěšně uložena.',
      saveError: 'Chyba při nahrávání na server',
      serverFailure: 'Server selhal.',
      serverForbidden: 'Nedostatečné oprávnění uživatele k provedení analýzy.',
    },
    formAlerts: {
      doneError: 'Analýzu se nepovedlo odeslat ke schválení.',
      doneErrorAdmin: 'Analýzu se nepovedlo schválit.',
      doneSuccess: 'Analýza je připravena ke schválení.',
      doneSuccessAdmin: 'Analýza úspěšně schválena.',
      refuseError: 'Analýzu se nepodařilo odmítnout.',
      refuseSuccess: 'Analýza úspěšně odmítnuta.',
    },
    header: {
      project: 'Projekt',
      title: 'Detail stromu č.',
    },
    inputs: {
      analysisLevel: 'Level analýzy',
      announcement: 'Hlášení',
      condition: 'Celkový stav',
      dbh: {
        calculated: {
          add: 'Přidat DBH',
          errorMsg: 'Musí být vyplněno.',
          label: 'DBH vypočítáno',
          remove: 'Odstranit DBH',
        },
        treeScanner: {
          add: 'Přidat DBH',
          label: 'DBH z TreeScanneru',
          remove: 'Odstranit DBH',
        },
      },
      diameter: {
        helperText: 'Po analýze stromu',
        label: 'Průměr (cm)',
      },
      formButtons: {
        done: 'Hotovo',
        refuse: 'Odmítnout',
        save: 'Uložit',
      },
      height: {
        helperText: 'Z mobilní aplikace',
        label: 'Výška stromu z TreeScanneru (m)',
      },
      incorrectNumber: 'Nekorektní číslo',
      incorrectValue: 'Nesprávná hodnota',
      mistletoe: 'Jmelí',
      notCalculated: 'Nevypočteno',
      profile: {
        city: 'Město',
        countryside: 'Krajina',
        label: 'Profil',
        outskirts: 'Předměstí',
        village: 'Vesnice',
      },
      protectionFactor: {
        alley: 'Alej',
        forestStand: 'Porost',
        label: 'Ochranný faktor',
        solitaire: 'Solitér',
      },
      searchTaxon: 'Vyhledejte Taxon',
      selectTaxon: 'Prosím, určete taxon!',
      taxon: 'Taxon',
      tomogramType: 'Typ tomogramu',
      treeTopHeight: {
        label: 'Výška koruny (m)',
        helperText: 'Po analýze stromu',
      },
    },
    mobileHints: {
      avgValue: 'Průmerná hodnota',
      cmAbbr: 'cm',
      fromApp: 'Hodnota z mobilní aplikace',
      photo: 'Hodnota z fotky č.',
      scale: 'Původní meřítko',
    },
    modals: {
      body: {
        hintBase: 'Klikněte na střed kmene, kde se potkává s půdou.',
        hintCrown: 'Klikněte a vyznačte tři body. Počátek koruny a její šířku dvěma bodmi na její konci.',
        hintScale: 'Klikněte na počátek výškového meřítka. Posouvaním koncových bodů upravte jeho velikost.',
        hintTrunk: 'Klikněte a vyznačte body kmene.',
        markBase: 'Vyznačit bázi',
        markCenterPoint: 'Vyznačit hranici kmene a koruny',
        markCrown: 'Vyznačit korunu',
        markRange: 'Vyznačit rozpětí koruny',
        markScale: 'Vyznačit meřítko',
        markTopPoint: 'Vyznačit vrchni hranici stromu',
        markTrunk: 'Vyznačit kmen',
        title: 'Detail stromu',
        type: 'Kmen',
        typeCrown: 'Koruna',
      },
      cancel: 'Zrušit',
      error: 'Chyba',
      hint: 'Nápověda pro',
      nowEdit: 'Právě editujete',
      recalculate: 'Přepočítat',
      rejection: {
        cancel: 'Zrušit',
        headline: 'Napište prosím důvod zamítnutí',
        hint: 'Vyplňte důvod, prosím!',
        placeholder: 'Uveďte důvod',
        refuse: 'Zamítnout',
        title: 'Zamítnutí stromu',
      },
      save: 'Uložit',
      saved: 'Uloženo',
      saveError: 'Analýza stromu nebyla korektně uložena, prosím zkuste znovu.',
      saveSucc: 'Analýza byla uložena.',
      scale: 'Měřítko',
      tooltip: 'Vygeneruje mřížku a objekty na základě vytvořených bodů.',
      zoom: 'Přiblížení',
    },
    noPhotos: 'Žádné foto',
    orders: {
      errorMsg: 'Objednávka nebyla přijata',
      successMsg: 'Objednávka byla prijata',
    },
    overview: 'Přehled',
    photoEditing: {
      deleteImage: 'Opravdu chcete fotku smazat?',
      deleteImageNo: 'Ne',
      deleteImageYes: 'Ano',
      deletePhoto: 'Smazat fotku',
      form: {
        deleteSucc: 'Fotka byla smazána',
        description: 'Popis',
        downloadTrunkScan: 'Stáhnout sken kmene',
        photoNumber: 'Číslo fotky',
        save: 'Uložit',
        saveError: 'Fotku se nepodařilo uložit',
        saveSucc: 'Fotka byla uložena',
        uploadTrunkScan: 'Nahrát upravený sken kmene',
        uploadTrunkScanImage: 'Nahrát snímek skenu kmene',
      },
      imageCopy: 'Kopie obrázku:',
      noActivePhoto: 'Vyberte fotku k editaci',
      title: 'Úprava fotek',
      trunk: 'Kmen',
      whole: 'Celý strom',
    },
    photoEditSuccess: 'Fotka úspěšně upravena',
    photoError: 'Nepovedlo se upravit fotku',
    photoSuccess: 'Fotka úspěšně uložena',
    site: 'Plocha',
    tensilTest: {
      anchorageHeight: 'Výška kotvení',
      barkThickness: 'Tloušťka kůry',
      bendingMomentAtBase: 'Ohybový moment - pata kmene',
      bendingMomentAtMeasurementPosition: 'Ohybový moment - pozice měření',
      calculate: 'Vypočítat',
      calculateProtocol: 'Vypočítat hodnoty protokolu',
      cleanDiameter: 'Čistý průměr kmene',
      criticalBendingMoment: 'Kritický ohybový moment',
      crossSectionalArea: 'Plocha průřezu',
      bendingCrossSectionModulus: 'Ohybový průřezový modul',
      torsionCrossSectionModulus: 'Torzní průřezový modul',
      crownExcentricity: 'Excentricita koruny',
      crownWeight: 'Hmotnost koruny',
      deadWeightLoad: 'Zatížení vlastní hmotností',
      deformation: 'Deformace',
      deformationLimit: 'Limit deformace zlomu',
      dynamometer: 'Dynamometr',
      E: 'E',
      force: 'Síla',
      inclination: 'Náklon',
      ktDistance: 'Vzdálenost kT',
      measurementHeight: 'Výška měření',
      elasticityModulus: 'Modul pružnosti',
      momentOfInertia: 'Moment setrvačnosti',
      noData: 'Nebyly nalezeny žádné tahové zkoušky',
      note: 'Poznámka od zákazníka',
      precalculate: 'Spočítat náhledové tabulky',
      precalculations: 'Náhledové tabulky',
      pull: 'Tah',
      push: 'Tlak',
      relativeDeformation: 'Poměrná deformace',
      uprootingResistanceSafetyFactor: 'Odolnost proti vývratu',
      fractureResistanceSafetyFactor: 'Odolnost proti zlomu',
      resultFor: 'Výsledné hodnoty pro: ',
      resultValuesOfMeasurement: 'Výsledné hodnoty měření',
      ropeAngle: 'Úhel lana',
      safeCoef: 'Bezpečnostní koeficient',
      torque: 'Torzní moment',
      torsionResistanceSafetyFactor: 'Odolnost proti krutu',
      reject: 'Zamítnout',
      save: 'Uložit hodnoty protokolu',
      saveError: 'Soubor se nepodařilo nahrát',
      saveSuccess: 'Tahové zkoušky úspěšně nahrány',
      saving: 'Ukládání výpočtů tahové zkoušky',
      shearStrength: 'Pevnost ve smyku',
      shearStress: 'Napětí ve smyku',
      compressiveStress: 'Napětí v ohybu',
      showRawData: 'Surové data',
      substitutionForce: 'Výsledná síla působící na strom',
      taxon: 'Taxon',
      title: 'Data z tahové zkoušky',
      testNumber: 'Tahová zkouška č. ',
      trunkDiameter1: 'Průměr kmene 1',
      trunkDiameter2: 'Průměr kmene 2',
      trunkVolume: 'Objem kmene',
      trunkWeight: 'Hmotnost kmene',
      uploadData: 'nahrát data',
      variableDeformation: 'Poměrová deformace',
      windLoadToPullingForceRatio: 'Podíl zatížení větrem',
    },
    tomograms: {
      deleteImage: 'Smazat snímek',
      downloadImage: 'Stáhnout snímek',
      noData: 'Nebyly nalezeny žádné tomogramy',
      showImage: 'Zobrazit snímek',
      showNote: 'Zobrazit poznámku',
      title: 'Tomogramy',
      uploadImage: 'Nahrát snímek',
    },
    treeTopAnalysis: 'Analýza koruny',
    treeTopPart: {
      treeTop: 'Kmen',
    },
    trunkAnalysis: {
      height: 'Výška skenu kmene',
      save: 'Uložit',
      title: 'Analýza kmene',
      trunkScan: 'Sken kmene',
    },
    trunkPart: {
      wholeTree: 'Celý strom',
    },
    trunkScanError: 'Nepovedlo se nahrát nový scan kmene.',
    trunkScanSuccess: 'Scan kmene úspěšně nahrán',
  },
  treeState: {
    [TreeState.Active]: 'Aktivní',
    [TreeState.Approved]: 'Schválený',
    [TreeState.NonActive]: 'Neaktivní',
  },
};
