// apiHelpers

import * as Sentry from '@sentry/browser';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL, ARBO_CORE_API, COOKIE_NAME, PULLING_TESTS_API_URL } from '../constants/constants';
import {
  Elastometer,
  SensorTag,
  TensileTestStatus,
} from '../interfaces/tenstilTest';
import {
  TreeProfile,
  TreeProtectionFactor,
  TreeState,
  UserRole,
} from '../interfaces/enums';
import { NotificationDTO } from '../interfaces/notification';
import { Project } from '../interfaces/project';
import {
  LocationResponse,
  PhotoResponse,
  TreeResponse,
} from '../interfaces/responses';
import {
  AnalysisEvaluation,
  AnalysisLevelType,
  AnnouncementType,
  Photo,
  Tomogram,
  TomogramAnalysis,
  Tree,
  TreeCalculations,
  TreeScannerDataStatus,
} from '../interfaces/tree';
import { OperatorDTO, UserAccess, UserDTO } from '../interfaces/user';
import { TensileTestFormProps } from '../components/treeDetailPageNew/components/TensilTests/TensileTestForm';


export const instance = axios.create({
  baseURL: API_URL,
});



export const apiCoreInstance = axios.create({ baseURL: ARBO_CORE_API });

export const postLogin = async (email: string, password: string) => {
  const postObject = {
    identifier: email,
    password,
  };

  return instance.post(`/auth/local`, postObject);
};

export const getProjects = async () => {
  return instance.get(`/projects`);
};

export const setAuthToken = (token?: string): void => {
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
};

export const refreshTokenFromCookie = async (
  cookie: string | undefined,
): Promise<UserDTO | undefined> => {
  if (cookie) {
    try {
      const obj = JSON.parse(cookie);
      const jwt = obj.jwt;
      setAuthToken(jwt);
      return obj.user;
    } catch (err) {
      Sentry.captureException(err);
    }
  }
};

refreshTokenFromCookie(Cookies.get(COOKIE_NAME));

export const getOperators = async () => {
  return instance.get(`/users?role.name=${UserRole.Operator}`);
};

export const getTrees = async (idProject?: number, page: number = 1) => {
  if (idProject) {
    return instance.get(`/tree-scanner-data?project=${idProject}&page=${page}`);
  }
  return instance.get(`/tree-scanner-data?page=${page}`);
};

export const postTreeAnalysisResult = async (payload: any) => {
  return instance.post(`/tree-scanner-analysis-data`, payload);
};

export const putTreeAnalysisResult = async (payload: any) => {
  return instance.put(`/tree-scanner-analysis-data/${payload.id}`, payload);
};

export const postRejectAnalysis = async (payload: any) => {
  return instance.post(`/tree-scanner-data/${payload.id}/reject`, {
    rejectedReason: payload.rejectedReason,
  });
};

export const postAcceptAnalysis = async (payload: any) => {
  return instance.post(`/tree-scanner-data/${payload.id}/approve`, {});
};

export const putTrunkScan = async (payload: File, treeId: number) => {
  const formData = new FormData();
  formData.append('files.trunkScan', payload);
  formData.append('data', JSON.stringify(''));
  return instance.put(`/tree-scanner-data/${treeId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const putTensilTestsFile = async (
  payload: { tensilTests: File },
  treeId: number,
  tensilTestId: number,
) => {
  const formData = new FormData();
  formData.append('files.tensilTest', payload.tensilTests);
  formData.append('data', JSON.stringify(''));
  return instance.put(
    `/tree-scanner-data/${treeId}?tensilTest=${tensilTestId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const putTomogramFile = async (
  id: number,
  file: string | Blob | RcFile,
) => {
  const formData = new FormData();
  formData.append('files.image', file);
  formData.append('data', JSON.stringify(''));
  return instance.put<Tomogram>(`/tree-scanner-tomograms/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const removeTomogramFile = async (id: number) => {
  return instance.put<Tomogram>(`/tree-scanner-tomograms/${id}`, {
    image: null,
  });
};

export const postEvaluateTreeAnalysis = async (payload: AnalysisEvaluation) => {
  const postVerdict = {
    id: payload.analysisId,
    status: payload.status,
    rejection_message: payload.rejectionMsg,
  };
  return instance.post(`/adbian_tree_snapshot_analysis`, postVerdict);
};

export const getSingleTree = async (treeId: number) => {
  return instance.get(`/tree-scanner-data/${treeId}`);
};

export const getProjectById = async (projectId: number) => {
  return instance.get(`/projects/${projectId}`);
};

export const assignOperators = async (
  idProject: number,
  idOperators: number[],
) => {
  const postObject = {
    project_id: idProject,
    operator_ids: idOperators,
  };
  return instance.post(`/adbian_tree_snapshot_project_operators`, postObject);
};

export const deleteTreePhoto = async (idPhoto: number) =>
  instance.delete(`tree-scanner-images/${idPhoto}`);

export const updateTreePhoto = async (photo: Photo) => {
  return instance.put(`tree-scanner-images/${photo.id}`, photo);
};

export const unassignOperators = async (idsProjectOperator: number[]) => {
  const postObject = {
    project_operator_ids: idsProjectOperator,
  };
  return instance.post(`/ats_delete_project_operators_actions`, postObject);
};

export const getTaxons = async () => {
  try {
    const taxons = (await instance.get(`/taxons`)).data;
    const taxonStrings: string[] = [];
    if (taxons) {
      taxons.forEach(taxon => {
        if (taxon.taxon) {
          taxonStrings.push(taxon.taxon);
        }
      });
      return taxonStrings;
    }
  } catch (err) {
    Sentry.captureException(err);
  }
  return [];
};

export const parseCookieToLoggedUser = (cookie: string | undefined) => {
  if (cookie) {
    try {
      const obj = JSON.parse(cookie);
      const user: UserDTO = {
        id: obj.user.id,
        username: obj.user.username,
        role: obj.user.role.name,
        email: obj.user.email,
        confirmed: obj.user.confirmed,
        blocked: obj.user.blocked,
        analysisOfTrunk: obj.user.analysisOfTrunk,
        analysisOfTreetop: obj.user.analysisOfTreetop,
        analysisOfRoad: obj.user.analysisOfRoad,
        accesses: obj.user.accesses,
      };
      return user;
    } catch (err) {
      Sentry.captureException(err);
    }
  } else {
    return undefined;
  }
};

export const parseStates = (value: string) => {
  if (value === 'TODO') {
    return TreeState.Active;
  } else {
    return TreeState.NonActive;
  }
};

export const getSingleProject = (project?: string | number | Project) => {
  if (!project) {
    return null;
  }
  if (typeof project === 'number' || typeof project === 'string') {
    return getProjectById(+project);
  }
  return getProjectById(project.id);
};

export const convertSingleTreeHelper = async (t: TreeResponse) => {
  const tree: Tree = {
    id: t.id,
    status: t.status,
    trunkScanUrl: t.trunkScan ? t.trunkScan.url : '',
    analysisLevel: t?.analysisLevel,
    dbh: t?.dbh && t.dbh,
    height: t.height ? Number(t.height) : 0,
    treeNumber: t?.treeMetadata?.treeNumber || 0,
    project: t.treeMetadata.project,
    site: t.treeMetadata.site,
    taxon: t.treeMetadata.taxon,
    treeSid: t.treeMetadata.treeSid,
    announcement:
      t?.tree_scanner_analysis_data?.announcement || AnnouncementType.None,
    condition: t?.tree_scanner_analysis_data?.condition || '1',
    mistletoe: t?.tree_scanner_analysis_data?.mistletoe || '1',
    protectionFactor:
      t?.tree_scanner_analysis_data?.protectionFactor ||
      TreeProtectionFactor.NONE,
    profile: t?.tree_scanner_analysis_data?.profile || TreeProfile.None,
    isAnalysed: t?.tree_scanner_analysis_data?.isAnalysed,
    isApproved: t?.tree_scanner_analysis_data?.isApproved,
    treeMetadata: t.treeMetadata,
    tree_scanner_tomograms: t.tree_scanner_tomograms || [],
    tree_scanner_analysis_data: t.tree_scanner_analysis_data,
    tree_scanner_images: t.tree_scanner_images
      ? t.tree_scanner_images.map((p: PhotoResponse) => ({
          id: p.id,
          analysed: p!.analysed,
          analysisResults: p!.analysisResults,
          url: p.image?.url && p.image.url,
          latitude: p.latitude,
          longitude: p.longitude,
          type: p.type,
          zAxis: p.zAxis,
          photoNumber: p.photoNumber,
          description: p.description,
          calibration: p.calibration,
          exif: p.exif,
          distance: p.distance,
          imageCopy: p.imageCopy || { url: '' },
          created_at: p.created_at,
        }))
      : [],
    scale: t.scale ? t.scale : 0,
    latitude: t.treeMetadata.latitude,
    longitude: t.treeMetadata.longitude,
    deviceInfo: t.deviceInfo
      ? {
          deviceId: t.deviceInfo.device_id,
          deviceName: t.deviceInfo.device_name,
          installationId: t.deviceInfo.installation_id,
          platform: t.deviceInfo.platform,
        }
      : undefined,
    directionOfLoad:
      t.directionOfLoad && t.directionOfLoad.length > 0
        ? t.directionOfLoad
        : [],
    tensilTests: t.tensilTests && t.tensilTests.length > 0 ? t.tensilTests : [],
    structure: t.structure
      ? t.structure.map((s: LocationResponse) => ({
          longitude: s.lon,
          latitude: s.lat,
        }))
      : [],
    report: t.report,
    rejectedReason: t.rejectedReason ? t.rejectedReason : '',
    operator: t.operator ? t.operator : null,
    rejectedMessage: t.rejectedMessage ? t.rejectedMessage : '',
    createdBy: t.createdBy,
  };
  return tree;
};

// helps convert trees from response to trees in app
export const convertTreesHelper = async (treeResponse: TreeResponse[]) => {
  const trees: Tree[] = await Promise.all(
    treeResponse.map(async (t: TreeResponse) => {
      const tree = await convertSingleTreeHelper(t);
      return tree;
    }),
  );
  return trees;
};

export const postTree = async (tree: TreeCalculations) =>
  instance.post(`trees`, tree);

export const putTree = async (id: number, tree: TreeCalculations) =>
  instance.put(`trees/${id}`, tree);

export const makeOrder = async (treeIds: number[], level: AnalysisLevelType) =>
  instance.post('orders/makeOrder', { treeIds, level });

export const postResetPassword = async (
  password: string,
  passwordAgain: string,
  token: string,
) =>
  instance.post('auth/reset-password', {
    code: token,
    password,
    passwordConfirmation: passwordAgain,
  });

export const putTreeScannerTomogram = async (id: number, sliceURL: string) => {
  const { data } = await instance.post('/tomogram-analysis-data', { sliceURL });

  instance.put(`/tree-scanner-tomograms/${id}`, {
    tomogramAnalysisData: data.id,
  });
};

export const putTomogramAnalysisData = async ({
  id,
  ...data
}: TomogramAnalysis) => {
  instance.put(`/tomogram-analysis-data/${id}`, data);
};

export const getNotifications = async () => {
  const { data } = await instance.get<NotificationDTO[]>(
    `/notifications?type=OC&hiddenAt_null=true&_sort=createdAt:desc`,
  );
  return data;
};

export const readOneNotification = async (props: {
  notificationId: number;
}) => {
  const { notificationId } = props;
  return await instance.put<NotificationDTO>(
    `/notifications/${notificationId}`,
    {
      viewedAt: new Date(),
    },
  );
};

export const readAllNotifications = async (props: {
  notificationIds: number[];
}) => {
  const { notificationIds } = props;

  return Promise.all(
    notificationIds.map(notificationId =>
      instance.put<NotificationDTO>(`/notifications/${notificationId}`, {
        viewedAt: new Date(),
      }),
    ),
  );
};
export const deleteAllNotifications = async (props: {
  notificationIds: number[];
}) => {
  const { notificationIds } = props;
  return Promise.all(
    notificationIds.map(notificationId =>
      instance.put<NotificationDTO>(`/notifications/${notificationId}`, {
        hiddenAt: new Date(),
      }),
    ),
  );
};

export const loadOperators = async () => {
  return instance.get<OperatorDTO[]>('/users/operators');
};

export const putOperator = async (props: {
  treeId: number;
  operatorId: number;
  isAdmin: boolean;
}) => {
  const { treeId, operatorId, isAdmin } = props;
  if (isAdmin) {
    return instance.put(`/tree-scanner-data/${treeId}`, {
      operator: operatorId,
    });
  }
};

export const postRejectedMessage = async (props: {
  id: number;
  message: string;
  status: TreeScannerDataStatus;
}) => {
  const { id, message, status } = props;
  return instance.put(`/tree-scanner-data/${id}`, {
    rejectedMessage: message,
    status,
  });
};

export const getAccesses = async (accessId?: number | null) => {
  if (!accessId) {
    return null;
  }
  const { data } = await instance.get<UserAccess>(`/accesses/${accessId}`);
  return data;
};


// common values per elastometer
type ElastometerCalculationCommonValues = {
  measurementHeight: number,
  trunkDiameter1: number,
  trunkDiameter2: number,
  barkThickness: number, // TODO: Maybe this should come from calculated values from report
  cleanDiameter: number,
  crossSectionModule: number,
  crossSectionalArea: number,
  momentOfInertia: number,
  shearStress: number,
  safetyFactor: number,
  trunkVolume: number,
  trunkWeight: number,
  crownWeight: number,
  deadWeightLoad: number,
};

type ElastometerCalculationValues = {
  // Shared values of quartil/protocol values
  dynamometer: number,
  windLoadToPullingForceRatio: number,
  bendingMomentAtBase: number,
  shearStrength: number,
  // unique values per quartil and per elastometer
  rawValue: number,
  relativeDeformation: number,
  bendingMomentAtMeasurementPosition: number,
  compressiveStress: number,
  moduleOfElasticity: number,
  resistanceToFracture: number,
  E: number,
};

export type InclinometerCalculationDto = {
  uprootingResistanceSafetyFactor: number,
  dynamometer: number,
};

export type TreeValuesCalculationsDto = {
  twistingMoment: number,
  crownExcentricity: number,
  anchorageHeight: number,
  ktDistance: number,
  ropeAngle: number,
  criticalBendingMoment: number,
  substitutionForce: number,
  barkThickness: number,
  variableDeformation: number,
};

export type ElastometerPrecalculationDto = {
  commonValues: ElastometerCalculationCommonValues,
  quartilValues: ElastometerCalculationValues[],
};

type PrecalculationsDto = {
  elastometers: ElastometerPrecalculationDto[],
  inclinometers: InclinometerCalculationDto[][],
  treeValues: TreeValuesCalculationsDto,
};

type ReportDataCalculation = {
  substitutionForce: number,
  centroid: number,
  maxCriticalBendingMoment: number,
  maxCrownExcentricity: number,
  height: number,
};

type PrecalculateValuesAPIDto = {
  formValues: TensileTestFormProps;
  reportData: ReportDataCalculation,
  availableElastometers: Elastometer[];
  availableInclinometers: SensorTag[];
  quartilValues: ProtocolDto[];
};



const pullingTestsApi = new URL('/pulling-tests/', PULLING_TESTS_API_URL);
const pullingTestsCalculationsApi = new URL('calculate/', pullingTestsApi);

export const precalculateValues = async (data: PrecalculateValuesAPIDto) => {
  const { data: precalculations } = await axios.post<PrecalculationsDto>(
    new URL('precalculate', pullingTestsCalculationsApi).href,
    data,
  );

  return precalculations;
};



export type ProtocolDto = {
  force?: number;
  elastometers: (number | undefined)[];
  inclinometers: (number | undefined)[];
};

type CalculateProtocolValuesAPIDto = {
  formValues: TensileTestFormProps;
  reportData: ReportDataCalculation,
  availableElastometers: Elastometer[];
  availableInclinometers: SensorTag[];
  protocolValues: ProtocolDto;
};


export type CalculatedElastometerProtocolData = {
  commonValues: ElastometerCalculationCommonValues,
  results: ElastometerCalculationValues,
} | undefined;

export type CalculatedIncliometerProtocolData = InclinometerCalculationDto | undefined;

export type CalculatedProtocolDataDto = {
  treeValues: TreeValuesCalculationsDto,
  elastometers: CalculatedElastometerProtocolData[],
  inclinometers: CalculatedIncliometerProtocolData[],
};

export const calculateProtocolValues = async (data: CalculateProtocolValuesAPIDto) => {
  const { data: calculations } = await axios.post<CalculatedProtocolDataDto>(
    new URL('protocol', pullingTestsCalculationsApi).href,
    data,
  );

  return calculations;
};


export const saveCalculatedProtocolValues = async (data: any) => {
  const {
    id: pullingTestId,
    ...updateData
  } = data;

  const { data: dataMessages } = await axios.patch(
    new URL(`${pullingTestId}`, pullingTestsApi).href,
    updateData,
  );

  return dataMessages;
};

export const rejectPullingTest = async (pullingTestId: number) => {
  const { data: dataMessages } = await axios.patch(
    new URL(`${pullingTestId}/status`, pullingTestsApi).href,
    { status: TensileTestStatus.REJECTED },
  );

  return dataMessages;
};

export const getTensilTestTaxons = async (query: string) =>
  instance.get<string[]>(`tensil-tests/taxons?taxon_contains=${query}`);
