import { Fragment, useEffect, useState } from 'react';
import {
  CartesianGrid,
  Dot,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CategoricalChartState } from 'recharts/types/chart/types';
import { projectTheme } from '../styles/variables';
import { DataKey } from 'recharts/types/util/types';

// @ts-ignore
function CDot({ selectedMin, selectedMax, strokeColor, ...props }) {
  let config = {
    stroke: strokeColor,
    strokeWidth: 1,
    r: 2,
  };

  if (selectedMin?.number === props?.payload?.number || selectedMax?.number === props?.payload?.number) {
    config = {
      stroke: 'blue',
      strokeWidth: 2,
      r: 5,
    };
  }

  return (
    <Dot {...props} {...config} />
  );
}

type LegendOnMouseEnterParams = Parameters<NonNullable<ConstructorParameters<typeof Legend>[0]['onMouseEnter']>>;
type LegendOnMouseLeaveParams = Parameters<NonNullable<ConstructorParameters<typeof Legend>[0]['onMouseLeave']>>;
type LegendOnMouseEventDataParam =
  LegendOnMouseEnterParams[0] |
  LegendOnMouseLeaveParams[0] |
  { dataKey: DataKey<string> };


type ActiveChartLines<T> = { [k in keyof T]?: boolean; };
type ChartLinesOpacity<T> = { [k in keyof T]?: number; };


export function AppChart<
  T extends Record<string, number>
>({
  data,
  xDataKey,
  yDataKeys,
  xDataUnit,
  yDataUnit,
  selectValue,
  min,
  max,
  strokeColors,
  strokeColorsArr,
  defaultActiveLines,
}: {
  data: T[];
  xDataKey: keyof T;
  xDataUnit?: string;
  yDataKeys: (keyof T)[];
  yDataUnit?: string;
  selectValue?: ((e: CategoricalChartState) => void);
  min: number;
  max: number;
  strokeColors?: { [k in keyof T]?: string };
  strokeColorsArr?: string[];
  defaultActiveLines?: ActiveChartLines<T>;
}) {

  const [opacity, setOpacity] = useState<ChartLinesOpacity<T>>(
    Object.fromEntries(yDataKeys.map(dataKey => [dataKey, 1])) as ChartLinesOpacity<T>
  );

  const [activeLines, setActiveLines] = useState<ActiveChartLines<T>>(
    defaultActiveLines ||
    Object.fromEntries(yDataKeys.map(key => [key, true])) as ActiveChartLines<T>
  );

  const [chartWidth, setChartWidth] = useState<number>(window.innerWidth - 150);
  useEffect(() => {
    const updateChartWidth = () => setChartWidth(window.innerWidth - 150);
    window.addEventListener('resize', updateChartWidth);

    return () => window.removeEventListener('resize', updateChartWidth);
  }, []);

  const selectedMin = min > 0 ? data[min] : null;
  const selectedMax = min > 0 ? data[max] : null;

  const resetOpacity = () => {
    setOpacity(Object.fromEntries(yDataKeys.map(dataKey => [dataKey, 1])) as ChartLinesOpacity<T>);
  };

  const handleLegendMouseEnter = (o: LegendOnMouseEventDataParam) => {
    if (typeof o.dataKey === 'string' && activeLines[o.dataKey]) {
      const opacityValues = Object.fromEntries(yDataKeys.map(dataKey => [dataKey, 0.25])) as ChartLinesOpacity<T>;
      opacityValues[o.dataKey as keyof T] = 1;
      setOpacity(opacityValues);
    }
  };

  const handleLegendMouseLeave = (o: LegendOnMouseEventDataParam) => {
    resetOpacity();
  };

  const handleLegendClick = (o: LegendOnMouseEventDataParam) => {
    if (typeof o.dataKey !== 'string') {
      return;
    }

    const activeValues = { ...activeLines, [o.dataKey]: !activeLines[o.dataKey] };
    setActiveLines(activeValues);
    resetOpacity();
  };

  return (
    <LineChart data={data} onClick={selectValue} width={chartWidth} height={500}>
      <CartesianGrid strokeDasharray="1 1" />
      <XAxis dataKey={xDataKey as string} unit={xDataUnit} />
      <YAxis includeHidden={true} unit={yDataUnit} />
      <Legend
        key="legend"
        layout="vertical"
        align="left"
        verticalAlign="middle"
        iconType="circle"
        formatter={
          value => <span style={{ cursor: 'pointer', margin: 4, display: 'inline-block' }}>{value}</span>
        }
        onMouseEnter={handleLegendMouseEnter}
        onMouseLeave={handleLegendMouseLeave}
        onClick={handleLegendClick}
      />
      <Tooltip key="tooltip" />
      {yDataKeys.map((yDataKey, index) => {
        const strokeColor = strokeColors?.[yDataKey] || strokeColorsArr?.[index] || projectTheme.palette.primary.main;
        const CDotProps = { strokeColor, selectedMin, selectedMax };

        return (
          <Line
            connectNulls
            type="monotone"
            dataKey={yDataKey as string}
            key={`line-y-datakey-${yDataKey as string}`}
            stroke={strokeColor}
            strokeOpacity={opacity[yDataKey] ?? 1}
            hide={!activeLines[yDataKey]}
            activeDot={{
              r: 6,
              stroke: 'red',
              fill: 'white',
            }}
            dot={(
              <CDot key={`cdot-${yDataKey as string}`} {...CDotProps} />
            )}
          />
        );
      })}
      {min > 0 ? (
        <Fragment key="minimal-values-fragment">
          <ReferenceLine x={data[min][xDataKey] as number} stroke="#660227" key={`refer-min-x-${xDataKey as string}`} />
          {yDataKeys.map(yDataKey => !activeLines[yDataKey] ? null : (
            <Fragment key={`y-min-dataset-${yDataKey as string}`}>
              <ReferenceDot
                x={data[min][xDataKey] as number}
                y={data[min][yDataKey]}
                stroke="#660227"
                fill="white"
                r={6}
                key={`refdot-min-${yDataKey as string}`}
              />
              <ReferenceLine y={data[min][yDataKey]} stroke="#660227" key={`refer-min-y-${yDataKey as string}`}>
                <Label position="bottom">{`Min ${data[min][yDataKey]}`}</Label>
              </ReferenceLine>
            </Fragment>
          ))}
        </Fragment>
      ) : null}
      {max > 0 ? (
        <Fragment key="maximal-values-fragment">
          <ReferenceLine x={data[max][xDataKey] as number} stroke="#227d04" key={`refer-max-x-${xDataKey as string}`} />
          {yDataKeys.map(yDataKey => !activeLines[yDataKey] ? null : (
            <Fragment key={`y-max-dataset-${yDataKey as string}`}>
              <ReferenceDot
                x={data[max][xDataKey] as number}
                y={data[max][yDataKey]}
                stroke="#227d04"
                fill="white"
                r={6}
                key={`refdot-max-${yDataKey as string}`}
              />
              <ReferenceLine y={data[max][yDataKey]} stroke="#227d04" key={`refer-max-y-${yDataKey as string}`}>
                <Label position="top">{`Max ${data[max][yDataKey]}`}</Label>
              </ReferenceLine>
            </Fragment>
          ))}
        </Fragment>
      ) : null}
    </LineChart>
  );
}
