import { TreeFormData } from '../components/treeDetailPageNew/components/TreeDetailForm/types';
import { REPORTS_URL } from '../constants/constants';
import { Position } from '../interfaces/canvas';
import {
  SiteTab,
  TreeProfile,
  TreeProtectionFactor,
} from '../interfaces/enums';
import { ReportData } from '../interfaces/responses';
import {
  AnnouncementType,
  Photo,
  PhotoType,
  Tree,
  TreeCalculationsPhotos,
} from '../interfaces/tree';

export const treeImagesPartition = (
  images: Photo[],
): {
  crownPhotos: TreeCalculationsPhotos[];
  trunkPhotos: TreeCalculationsPhotos[];
} => {
  const crownPhotos: TreeCalculationsPhotos[] = [];
  const trunkPhotos: TreeCalculationsPhotos[] = [];

  images.forEach((photo, index) => {
    if (photo.type === PhotoType.Whole && photo.analysisResults?.crownPoints) {
      const { centerPoint, topPoint, range, base, crownPoints } =
        photo.analysisResults;
      let points: Position[];

      if (centerPoint && topPoint) {
        points = [centerPoint, topPoint, ...range, ...base];
      } else if (centerPoint) {
        points = [centerPoint, ...base, ...crownPoints];
      } else {
        points = [];
      }
      if (points.length > 0) {
        crownPhotos.push({
          id: photo.id,
          points,
          url: photo.url,
          calibration: photo.calibration,
          exif: photo.exif,
          distance: photo.distance,
          location: { latitude: photo.latitude, longitude: photo.longitude },
          zAxis: photo.zAxis,
          created_at: photo.created_at,
          photoNumber: photo?.photoNumber || index + 1,
        });
      }
    }
    if (photo.type === PhotoType.Trunk && photo.analysisResults?.trunkPoints) {
      trunkPhotos.push({
        id: photo.id,
        points: photo.analysisResults.trunkPoints,
        url: photo.url,
        calibration: photo.calibration,
        exif: photo.exif,
        distance: photo.distance,
        location: { latitude: photo.latitude, longitude: photo.longitude },
        zAxis: photo.zAxis,
        created_at: photo.created_at,
        photoNumber: photo?.photoNumber || index + 1,
      });
    }
  });

  return { crownPhotos, trunkPhotos };
};

export const getRouteLink = (treeId: string | number, siteTab?: SiteTab) => {
  return `/treeDetail/${treeId}/${siteTab ? siteTab : ''}`;
};

export const generateReportUrl = (treeSid: ReportData['sid'], language: string, units: string = 'metric') => {
  // TOOD: move to config
  const reportUrl = new URL('/tree-report', REPORTS_URL);
  reportUrl.searchParams.set('treeSid', treeSid);
  reportUrl.searchParams.set('language', language);
  if (units !== 'metric') {
    reportUrl.searchParams.set('units', units);
  }
  return reportUrl.href;
};

export const parseTreeToForm = (tree: Tree): TreeFormData => {
  return {
    id: tree.id,
    analysisLevel: tree.tree_scanner_analysis_data?.analysisLevel!,
    projectId: tree.project.id,
    project: tree.project.name,
    site: tree.site,
    treeTopHeight: tree.tree_scanner_analysis_data?.treeTopHeight
      ? tree.tree_scanner_analysis_data.treeTopHeight
      : '',
    taxon: tree.taxon || '',
    announcement: tree.tree_scanner_analysis_data?.announcement
      ? tree.tree_scanner_analysis_data.announcement
      : AnnouncementType.None,
    dbh: tree.dbh ? tree.dbh : '',
    dbhCounted: tree.tree_scanner_analysis_data?.dbh
      ? tree.tree_scanner_analysis_data.dbh
      : '',
    height: tree.tree_scanner_analysis_data?.height
      ? tree.tree_scanner_analysis_data.height
      : tree.height,
    protectionFactor: tree.tree_scanner_analysis_data?.protectionFactor
      ? tree.tree_scanner_analysis_data.protectionFactor
      : TreeProtectionFactor.NONE,
    profile: tree.tree_scanner_analysis_data?.profile
      ? tree.tree_scanner_analysis_data.profile
      : TreeProfile.None,
    mistletoe: tree.tree_scanner_analysis_data?.mistletoe
      ? `${tree.tree_scanner_analysis_data.mistletoe}`
      : '1',
    condition: tree.tree_scanner_analysis_data?.condition
      ? `${tree.tree_scanner_analysis_data?.condition}`
      : '1',
    tree_scanner_data: tree.id,
    isAnalysed: tree.tree_scanner_analysis_data?.isAnalysed
      ? tree.tree_scanner_analysis_data.isAnalysed
      : !!tree?.isAnalysed,
    isApproved: tree.tree_scanner_analysis_data?.isApproved,
    treeMetadata: tree.treeMetadata.id,
    trunkScanHeight: tree.tree_scanner_analysis_data?.trunkScanHeight || 0,
    trunkScanScreen: tree.tree_scanner_analysis_data?.trunkScanScreen || null,
  };
};
