import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { useTensilTestTaxon } from '../hooks/useTensilTestTaxon';
import { useCurrentLanguage } from '../translations/languageSelector';

const inputName = 'taxon';

export const AppTensileTestsTaxonSelect: FC<{
  disabled?: boolean;
  rules?: RegisterOptions;
  showMessage?: boolean;
}> = ({ disabled, rules, showMessage }) => {
  const activeLang = useCurrentLanguage();

  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const { taxons, setTaxonQuery, isTaxonsLoading } = useTensilTestTaxon(
    getValues(inputName),
  );

  useEffect(() => {
    if (!isTaxonsLoading) {
      const value = getValues(inputName);
      value && !taxons?.includes(value) ?
        setError(inputName, { type: 'manual', message: 'taxon nam chyba' }) :
        clearErrors(inputName);
    }
  }, [setError, clearErrors, taxons, inputName, isTaxonsLoading, getValues]);


  const error = errors[inputName];
  const [isOpen, setIsOpen] = useState(false);

  const onTextInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const inputValue = e.target.value;

      if (!inputValue) {
        setIsOpen(false);
        setTaxonQuery('');
        return;
      }

      setIsOpen(true);
      setTaxonQuery(inputValue);
    },
    [setTaxonQuery],
  );

  const onAutocompleteChange = useCallback(
    (_e: ChangeEvent<{}>, value: string) => {
      console.log({ value });
      setValue('taxon', value);
    },
    [setValue],
  );

  return (
    <Controller
      control={control}
      name={inputName}
      rules={rules}
      render={({ field: { value, name } }) => {
        return (
          <Autocomplete
            disabled={disabled}
            renderInput={params => (
              <TextField
                {...params}
                name={name}
                label={activeLang.treeDetailPage.tensilTest.taxon}
                onChange={onTextInputChange}
                variant="outlined"
                helperText={showMessage ? error?.message : undefined}
                error={!!error?.message}
              />
            )}
            loading={isTaxonsLoading}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={onAutocompleteChange}
            onInputChange={(_e, _v, reason) => reason === 'clear' && setTaxonQuery('')}
            placeholder=""
            value={value}
            size="small"
            options={taxons}
            fullWidth
          />
        );
      }}
    />
  );
};
