import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ButtonGroup, Container } from '@material-ui/core';
import { Button, Table } from 'antd';
import { css } from 'aphrodite';
import { styles } from './FinishedOrderDetail.styles';

import { defaultLang, useCurrentLanguage } from '../../translations/languageSelector';

import { NoDataTab } from '../../components/treeDetailPageNew/components/NoDataTab/NoDataTab';

import { LoadingState /*, ReportStatus */ } from '../../interfaces/enums';
import { TreeScannerDataStatus } from '../../interfaces/tree';
import {
  getDetail as getFinishedOrderDetail,
  setReadyForClient,
} from '../../api/finishedOrder/fetchers/finishedOrderDetails';
import { Loader } from '../../components/shared/Loader';
import { generateReportUrl } from '../../helpers/treeHelpers';
import { useCurrentUser } from '../../hooks/useCurrentUser';


/*
  Helper component, to wrap antd buttons, so they
  don't pass MUI's properties down to HTML elements
*/
const Span = ({ children }) => children;

export const FinishedOrderDetail = ({ showErrorMessage, showSuccessMessage }) => {
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState<any[] | null>(null);

  const activeLang = useCurrentLanguage();
  const loggedUser = useCurrentUser();

  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();
  const { mutate } = useMutation(setReadyForClient);

  const { data, isLoading } = useQuery(
    ['finishedOrders', id, page, perPage],
    () => getFinishedOrderDetail(id, page, perPage),
  );


  // Loading callback handlers
  const onError = () => {
    showErrorMessage();
  };

  const onSuccess = () => {
    showSuccessMessage();
    queryClient.invalidateQueries(['finishedOrders', id, page, perPage]);
    queryClient.invalidateQueries(['finishedOrders']);
  };


  const onSendWholeOrder = () => mutate({ orderId: id }, { onError, onSuccess });
  const onSendSelected = () => (selected?.length) && mutate({ selectedIds: selected }, { onError, onSuccess });

  const loadingState: LoadingState = isLoading ? LoadingState.Loading : LoadingState.Success;

  return (
    <Container maxWidth="xl" className={css(styles.container)}>
      <Loader requestState={loadingState} errorMsg="">
        {data ? (
          <>
            <ButtonGroup fullWidth={true}>
              <Span>
                <Button
                  onClick={onSendWholeOrder}
                  className={css(styles.activeButton)}
                >
                  {activeLang.finished.sendWholeOrder}
                </Button>
              </Span>

              <Span>
                <Button
                  onClick={onSendSelected}
                  disabled={!selected?.length}
                  className={selected?.length ? css(styles.activeButton) : ''}
                >
                  {activeLang.finished.sendSelected}
                </Button>
              </Span>
            </ButtonGroup>

            <Table
              rowKey="id"
              rowSelection={{
                selections: selected || [],
                getCheckboxProps: record => ({
                  disabled:
                    record.status === TreeScannerDataStatus.READYFORCLIENT, // Column configuration not to be checked
                }),
                onChange: selectedRowKeys => {
                  selectedRowKeys.length === 0
                    ? setSelected([])
                    : setSelected(prev =>
                      prev && prev?.length > 0
                        ? [...prev, ...selectedRowKeys]
                        : [...selectedRowKeys],
                    );
                },
              }}
              pagination={{
                total: data.metadata.total,
                showSizeChanger: true,
                pageSizeOptions: ['10', '25', '50', '100', '200', '500'],
                pageSize: perPage,
                current: page,
                onChange: (p, ps) => {
                  setPage(p);
                  if (ps) setPerPage(ps);
                },
              }}
              columns={[
                {
                  key: 'id',
                  dataIndex: 'id',
                  title: 'ID',
                  render: value => (
                    <Link to={`/treeDetail/${value}`}>{value}</Link>
                  ),
                },
                {
                  key: 'status',
                  dataIndex: 'status',
                  title: 'Status',
                  render: value => {
                    const problematicStatuses = [TreeScannerDataStatus.FAILED, TreeScannerDataStatus.REJECTED];
                    return (
                      <div
                        className={css(
                          styles.statusBadge,
                          problematicStatuses.includes(value) && styles.problematicStatusBadge,
                        )}
                      >
                        {value}
                      </div>
                    );
                  },
                },
                {
                  key: 'report',
                  dataIndex: 'report',
                  title: 'Report',
                  render: value => {
                    if (!value) return null;
                    return (
                      <div className={css(styles.report)}>
                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.treeNumber}
                          </div>
                          {value.number && value.number}
                        </div>

                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.tagNumber}
                          </div>
                          {value.tagNumber && value.tagNumber}
                        </div>

                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.project}
                          </div>
                          {value.projectName && value.projectName}
                        </div>

                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.groupName}
                          </div>
                          {value.groupName && value.groupName}
                        </div>

                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.areaName}
                          </div>
                          {value.areaName && value.areaName}
                        </div>
                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.taxon}
                          </div>
                          {value.taxon && value.taxon}
                        </div>
                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.dbh}
                          </div>
                          {value?.data?.data?.calculatedDBH
                            ? value.data.data.calculatedDBH?.toFixed(3)
                            : value.dbh}
                        </div>

                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.safetyFactor}
                          </div>
                          {value?.data.data?.slices_data[0]?.safetyFactorBending &&
                            value?.data.data?.slices_data[0]?.safetyFactorBending.toFixed(3)}
                        </div>

                        <div className={css(styles.cell)}>
                          <div className={css(styles.cellTitle)}>
                            {activeLang.finished.report}
                          </div>
                          {/* value?.status === ReportStatus.FINISHED && */(
                            <a
                              href={generateReportUrl(value?.sid, loggedUser?.language || defaultLang)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {activeLang.finished.link}
                            </a>
                          )}
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              dataSource={data.results}
              loading={isLoading}
            />
          </>
        ) : (
          <NoDataTab title="No trees in order" />
        )}
      </Loader>
    </Container>
  );
};
