import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { CategoricalChartState } from 'recharts/types/chart/types';

import {
  precalculateValues as precalculateValuesApi,
  calculateProtocolValues as calculateProtocolValuesApi,
  saveCalculatedProtocolValues as saveCalculatedProtocolValuesApi,
  rejectPullingTest as rejectPullingTestApi,
  ProtocolDto,
} from '../helpers/apiHelper';

import {
  InputData,
  MomentTag,
  SelectedMoments,
  SensorTag,
  TensilTestsDTO,
} from '../interfaces/tenstilTest';
import { TensileTestFormProps } from '../components/treeDetailPageNew/components/TensilTests/TensileTestForm';
import { useSelector } from 'react-redux';
import { getActiveTreeById } from '../store/trees/selectors';
import { ReportData } from '../interfaces/responses';


export const useTensileTestPrecalculation = () => useMutation(precalculateValuesApi);
export const useTensileTestProtocolCalculation = () => useMutation(calculateProtocolValuesApi);
export const useSaveTensileTestProtocolledValues = () => useMutation(saveCalculatedProtocolValuesApi);
export const useRejectPullingTest = () => useMutation(rejectPullingTestApi);


/**
 * Finds the index of the nearest value in the given input array to the target value.
 *
 * @param input - Tensile test's input data array.
 * @param targetValue - The target value for finding its nearest value.
 * @param startIndex - The index of input data where to start the lookup.
 * @param endIndex - The ending index (maximum value) of the lookup.
 * @returns The index of the nearest force value.
 */
const findNearestValue = (input: InputData[], targetValue: number, startIndex: number, endIndex: number) =>
  input
    .slice(startIndex, endIndex)
    .reduce(
      (nearestDataPoint, currentDataPoint, currentIndex) => {
        const deltaValue = Math.abs(targetValue - currentDataPoint.force);
        return (deltaValue < nearestDataPoint.value) ? { index: currentIndex, value: deltaValue } : nearestDataPoint;
      },
      { index: -1, value: Number.MAX_SAFE_INTEGER },
    )
    .index + startIndex;


const findExtremePointsValuesIndexes = (input: InputData[], propName: string) => input.reduce(
  (acc, value, index) => {
    if (value[propName] > input[acc.max]?.[propName]) {
      acc.max = index;
    }
    if (value[propName] < input[acc.min]?.[propName]) {
      acc.min = index;
    }

    return acc;
  },
  {
    min: 0,
    max: 0,
  }
);

const getMedianValue = (input: InputData[], propName: string) => {
  const extremePoints = findExtremePointsValuesIndexes(input, propName);
  const deltaValue = input[extremePoints.max]?.[propName] - input[extremePoints.min]?.[propName];
  return input[extremePoints.min]?.[propName] + deltaValue / 2;
};

/**
 * Returns an index array representing the indices pointing to the nearest values
 * of the quartile force values based on the input data array.
 *
 * @param input - The array of tensile test's input data.
 * @param startIndex - The starting index of the range to consider.
 * @param endIndex - The ending (maximum) index of the range to consider.
 * @returns An array of indices representing the indices of the nearest values to the quartiles.
 */
const getQuartils = (input: InputData[], startIndex: number, endIndex: number) => {
  if (endIndex >= 0 && startIndex >= 0) {
    const maxValue = input[endIndex]?.force;
    const deltaForce = input[endIndex]?.force - input[startIndex]?.force;
    return [
      findNearestValue(input, maxValue - deltaForce * 0.75, startIndex, endIndex),
      findNearestValue(input, maxValue - deltaForce * 0.5, startIndex, endIndex),
      findNearestValue(input, maxValue - deltaForce * 0.25, startIndex, endIndex),
      endIndex,
    ];
  }

  return [-1, -1, -1, -1];
};


/**
 * Calculates the average value of an array of given numbers.
 *
 * @param array - The array of numbers to calculate the average from.
 * @returns The average value of the input array aňor .
 */
const average = (array?: number[]): number | undefined => {
  const result = array?.reduce(
    (acc, value) => (
      Number.isFinite(value) ? {
        sum: acc.sum + value,
        length: acc.length + 1,
      } : acc
    ),
    { sum: 0, length: 0 },
  );

  if (result?.length) {
    return result.sum / result.length;
  }
};


/**
 * Checks if the values in the protocol object are valid numbers.
 *
 * @param protocol - The ProtocolDto object to validate.
 * @returns true if all the values in the Protocol object are valid.
 */
const areProtocolValuesValid = (protocol: ProtocolDto) =>
  Number.isFinite(protocol.force) &&
  protocol.elastometers.some(Number.isFinite) &&
  protocol.inclinometers.some(Number.isFinite);


const TensileTestContext = createContext<ReturnType<typeof useTensileTestManager>>({
  computation: {
    // @ts-ignore
    dataRange: {
      min: -1,
      max: -1,
    },
    // @ts-ignore
    form: null,
  },
  common: {
    handlePaginationChange: (_newPage: number | TensilTestsDTO) => { },
    // @ts-ignore
    activeTensilTest: {},
  }
});

export const TensileTestProvider: FC<{ tensilTests: TensilTestsDTO[] }> = ({ children, tensilTests }) => {
  const value = useTensileTestManager(tensilTests);

  return (
    <TensileTestContext.Provider value={value}>
      <FormProvider {...value.computation.form}>{children}</FormProvider>
    </TensileTestContext.Provider>
  );
};

const extractReportData = (reportData: ReportData) => {
  const windLoadAnalysis = reportData?.data.data.windLoadAnalysis.reduce(
    (acc, val) => acc.bendingMoment > val.bendingMoment ? acc : val,
    reportData?.data.data.windLoadAnalysis[0],
  );
  const maxCrownExcentricity = Math.max(...reportData?.data.data.windLoadAnalysis?.map(i => i.excentricity) || []);

  return {
    substitutionForce: windLoadAnalysis?.substitutionForce || 0,
    centroid: windLoadAnalysis?.centroid || 0,
    maxCriticalBendingMoment: windLoadAnalysis?.bendingMoment || 0,
    maxCrownExcentricity,
    height: reportData?.height || 0,
  };
};

const useTensileTestManager = (tensileTests: TensilTestsDTO[]) => {
  const reportData = useSelector(getActiveTreeById)?.report;

  const [activeTensileTest, setActiveTensileTest] = useState(tensileTests[0]);
  const handlePaginationChange = useCallback(
    (pageNumber: number | TensilTestsDTO) => {
      const testData = tensileTests.find(tensileTest => tensileTest.id === pageNumber || tensileTest === pageNumber);
      setActiveTensileTest(testData || tensileTests[0]);

      const dataCalculationsInput = testData?.data?.calculations?.input;
      setMinValue(dataCalculationsInput?.min ?? -1);
      setMaxValue(dataCalculationsInput?.max ?? -1);
      setSelectedSensors(dataCalculationsInput?.selectedSensors || []);
      setSelectedMoments(dataCalculationsInput?.selectedMoments || {} as SelectedMoments);
    },
    [tensileTests],
  );


  const calculationsInput = activeTensileTest?.data?.calculations?.input;
  const input = activeTensileTest?.data?.inputData;

  const extremes = findExtremePointsValuesIndexes(input, 'force');

  const [min, setMinValue] = useState<number>(calculationsInput?.min || extremes.min || -1);
  const [max, setMaxValue] = useState<number>(calculationsInput?.max || extremes.max || -1);


  const quartilValues = useMemo(
    () => getQuartils(input, min, max),
    [input, min, max]
  );

  const [boundaryPickingMethod, setBoundaryPickingMethod] = useState<'auto' | 'min' | 'max'>('auto');
  const pickBoundary = useCallback(
    (chartStateData: CategoricalChartState) => {
      const clickedIndex = chartStateData.activeTooltipIndex ?? -1;
      const thresholdForceValue = getMedianValue(input, 'force');

      if (boundaryPickingMethod === 'min') {
        setMinValue(clickedIndex);
      }
      else if (boundaryPickingMethod === 'max') {
        setMaxValue(clickedIndex);
      }
      else if (boundaryPickingMethod === 'auto') {
        // compare with median as thrashold
        // TODO: add check for case when max index < min index
        if (input[clickedIndex].force < thresholdForceValue) {
          setMinValue(clickedIndex);
        }
        else {
          setMaxValue(clickedIndex);
        }
      }
    },
    [input, boundaryPickingMethod],
  );


  // Sensor lists setup and initialization
  const availableElastometers = useMemo(
    () => activeTensileTest?.data?.availableElastometers?.map(elasto => elasto.name) || [],
    [activeTensileTest],
  );
  const availableInclinometers = useMemo(
    () => activeTensileTest?.data?.availableInclinometers?.map(inclino => inclino.name) || [],
    [activeTensileTest],
  );
  const availableSensors: SensorTag[] = useMemo(
    () => [...availableElastometers, ...availableInclinometers],
    [availableElastometers, availableInclinometers],
  );


  // Sensors list updates and toggles
  const [selectedSensors, setSelectedSensors] = useState<SensorTag[]>(calculationsInput?.selectedSensors || []);
  const isSensorSelected = useCallback(
    (sensorName: SensorTag) => selectedSensors.includes(sensorName),
    [selectedSensors]
  );
  const toggleSelectedSensor = useCallback(
    (sensorName: SensorTag) => setSelectedSensors(
      availableSensors.filter(
        (availableSensor: SensorTag) => isSensorSelected(availableSensor) !== (sensorName === availableSensor)
      )
    ),
    [availableSensors, isSensorSelected],
  );


  // Moments list setup and initialization
  const [selectedMoments, setSelectedMoments] = useState<SelectedMoments>(calculationsInput?.selectedMoments || {});
  const isMomentSelected = useCallback(
    (momentName: MomentTag, index: number) => selectedMoments[momentName]?.includes(index) || false,
    [selectedMoments],
  );
  const toggleSelectedMoment = useCallback(
    (moment: MomentTag, index: number) => setSelectedMoments({
      ...selectedMoments,
      [moment]: quartilValues.filter(quartil => isMomentSelected(moment, quartil) !== (quartil === index)),
    }),
    [selectedMoments, isMomentSelected, quartilValues],
  );


  // Protocol setup and initialization
  const protocol: ProtocolDto = useMemo(
    () => {
      console.log('protocol update');
      return ({
        force: average(selectedMoments.force?.map(quartilValue => input[quartilValue]?.force)),
        elastometers: availableElastometers.map(
          (elastometer, elastometerNumber) => isSensorSelected(elastometer) ? average(
            selectedMoments[elastometer]?.map(quartilValue => input[quartilValue]?.elastometers[elastometerNumber])
          ) : undefined
        ),
        inclinometers: availableInclinometers.map(
          (inclinometer, inclinometerNumber) =>
            !isSensorSelected(inclinometer) ? undefined : average(selectedMoments[inclinometer]?.map(
              quartilValue => input[quartilValue]?.inclinometers[inclinometerNumber]?.value
            ))
        ),
      });
    },
    [selectedMoments, isSensorSelected, input, availableElastometers, availableInclinometers],
  );



  const {
    mutate: precalculate,
    data: precalculations,
    ...restPrecalculation
  } = useTensileTestPrecalculation();

  const precalculateValues = useCallback(
    // TODO: move type to better place
    (formValues: TensileTestFormProps) => {
      if (!activeTensileTest) {
        return console.log(`Can't precalculate values: Tensil test not selected!`);
      }
      if (min < 0 && max < 0) {
        return console.log(`Can't precalculate values: min and max values are less than 0!`);
      }

      precalculate({
        formValues,
        reportData: extractReportData(reportData!),
        availableElastometers: activeTensileTest?.data?.availableElastometers!,
        availableInclinometers,
        quartilValues: quartilValues.map(quartil => ({
          ...input[quartil],
          inclinometers: input[quartil].inclinometers.map(inclinometerValues => inclinometerValues.value)
        })),
      });
    },
    [input, min, max, quartilValues, activeTensileTest, reportData, availableInclinometers, precalculate],
  );


  const {
    mutate: calculateProtocol,
    data: calculations,
    ...restCalculation
  } = useTensileTestProtocolCalculation();

  const calculateProtocolValues = useCallback(
    (formValues: TensileTestFormProps) => {
      if (!activeTensileTest) {
        return console.log(`Can't calculate protocol values: Tensil test not selected!`);
      }
      if (min < 0 && max < 0) {
        return console.log(`Can't calculate protocol values: min and max values are less than 0!`);
      }

      calculateProtocol({
        formValues,
        reportData: extractReportData(reportData!),
        availableElastometers: activeTensileTest?.data?.availableElastometers!,
        availableInclinometers,
        protocolValues: protocol,
      });
    },
    [min, max, activeTensileTest, reportData, availableInclinometers, calculateProtocol, protocol],
  );


  const {
    mutate: saveCalculations,
    data: savedData,
    ...saveCalculatedProtocolRest
  } = useSaveTensileTestProtocolledValues();

  const saveCalculatedProtocolValues = data => saveCalculations({
    input: {
      min,
      max,
      selectedSensors,
      selectedMoments,
      quartilValues,
    },
    protocol,
    ...data
  });


  const {
    mutate: rejectPullingTest,
    data: rejectionData,
    ...rejectPullingTestRest
  } = useRejectPullingTest();


  const form = useForm<{
    anchorageHeight: number;
    barkThickness: number;
    ropeAngle: number;
    ktDistance: number;
    taxon: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      anchorageHeight: activeTensileTest.directionData.anchorageHeight,
      barkThickness: activeTensileTest.directionData.barkThickness,
      ropeAngle: activeTensileTest.directionData.ropeAngle,
      ktDistance: activeTensileTest.directionData.ktDistance,
      taxon: activeTensileTest.taxon ?? reportData?.taxon ?? '',
    },
  });


  const isLoading = restPrecalculation.isLoading
    || restCalculation.isLoading
    || saveCalculatedProtocolRest.isLoading
    || rejectPullingTestRest.isLoading;


  return {
    common: {
      handlePaginationChange,
      activeTensileTest,
      tensileTests,
      reportData,
    },
    computation: {
      form,
      dataRange: {
        min,
        max,
        boundaryPickingMethod,
        pickBoundary,
        setBoundaryPickingMethod,
      },
      results: {
        precalculations,
        calculations: (precalculations || isLoading) ? calculations : activeTensileTest?.data?.calculations?.results,
      },
      rejectPullingTest,
      precalculateValues,
      calculateProtocolValues,
      saveCalculatedProtocolValues,
      isLoading,
    },
    safetyCoeficientTable: {
      availableElastometers,
      availableInclinometers,
      availableSensors,
      isSensorSelected,
      toggleSelectedSensor,
      isMomentSelected,
      toggleSelectedMoment,
      input,
      protocol,
      areProtocolValuesValid,
      quartilValues,
    }
  };
};

export const useTensileTest = () => useContext(TensileTestContext);
