import { FC } from 'react';
import {
  Badge,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { css } from 'aphrodite';
import { useCurrentLanguage } from '../../../../translations/languageSelector';
import { dynamicStyles, getMeterBgColor, styles } from './TensilTests.styles';
import { CALCULATIONS } from '../../../../interfaces/tenstilTest';
import { useTensileTest } from '../../../../contexts/TensilTestsContext';
import { CalculatedElastometerProtocolData, InclinometerCalculationDto } from '../../../../helpers/apiHelper';
import AppButton from '../Form/AppButton/AppButton';
import { SaveOutlined } from '@material-ui/icons';
import { LoadingOutlined } from '@ant-design/icons';


export const UnitCell: FC<{ propertyName: keyof typeof CALCULATIONS, value: number }> = ({ propertyName, value }) => {
  const { unitRatio = 1 } = CALCULATIONS[propertyName] || {};

  return (
    <TableCell
      className={css(styles.unitCell)}
      data-unit={CALCULATIONS[propertyName]?.unit}
    >
      {(value * unitRatio)?.toFixed(CALCULATIONS[propertyName]?.precision)}
    </TableCell>
  );
};

export const Untranslated: FC<{ text: string }> = ({ text }) => (
  <span style={{ border: '1px solid red' }}>
    {text}
  </span>
);

export const Translate: FC<{ text: string }> = ({ text }) => {
  const activeLang = useCurrentLanguage();
  return activeLang.treeDetailPage.tensilTest[text] ?? (<Untranslated text={text} />);
};



const elastometersResultTableItems = [
  'dynamometer',
  'measurementHeight',
  'trunkDiameter1',
  'trunkDiameter2',
  'windLoadToPullingForceRatio',
  'bendingMomentAtBase',
  'bendingMomentAtMeasurementPosition',
  'relativeDeformation',

  'fractureResistanceSafetyFactor',
  'torsionResistanceSafetyFactor',

  'crownExcentricity',
  'criticalBendingMoment',
  'substitutionForce',
] as const;

const inclinometersResultTableItems = [
  'dynamometer',
  'uprootingResistanceSafetyFactor',
] as const;

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#ECF4F2',
    },
  },
}))(TableRow);



export const TensileTestResultsTable = () => {
  // TODO: add error messages from Form Status
  // const { handleSubmit } = useFormContext();

  const {
    common: {
      activeTensileTest,
    },
    computation: {
      dataRange: {
        min,
        max,
      },
      form: {
        getValues,
        // dirty,
      },
      results: {
        precalculations,
        calculations,
      },
      isLoading,
      saveCalculatedProtocolValues,
    },
  } = useTensileTest();

  if (!calculations) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" style={{ fontWeight: 700, margin: 40 }}>
        <Translate text="resultValuesOfMeasurement" />
      </Typography>

      <PullingTestElastometersResultsTable
        calculationValues={calculations.elastometers}
      />

      <PullingTestInclinometersResultsTable
        calculationValues={calculations.inclinometers}
      />

      <Grid container direction="row-reverse" style={{ padding: 10 }}>
        <AppButton
          onClick={() => saveCalculatedProtocolValues({
            id: activeTensileTest.id,
            taxon: getValues('taxon') || activeTensileTest.taxon,
            calculations,
            // protocol,
          })}
          variant="contained"
          color="primary"
          disabled={isLoading || !precalculations}
          startIcon={isLoading ? <LoadingOutlined /> : <SaveOutlined />}
        >
          <Translate text="save" />
        </AppButton>
      </Grid>
    </>
  );
};



const PullingTestElastometersResultsTable: FC<
  { calculationValues: CalculatedElastometerProtocolData[] }
> = ({ calculationValues }) => {
  const { safetyCoeficientTable: { availableElastometers } } = useTensileTest();

  const activeElastometer = calculationValues.find(elastometer => elastometer);
  if (!activeElastometer) {
    return null;
  }

  return (
    <Grid item className={css(styles.tensilTestCard)}>

      <Grid container justifyContent="space-between" style={{ padding: '5px 10px' }}>
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 700, margin: 10 }}>
            <Translate text="resultFor" />
            {'Elastometers'}
          </Typography>
        </Grid>
      </Grid>


      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />

            {calculationValues.map((elastometer, index) => {
              const name = availableElastometers[index];
              return (!elastometer) ? null : (
                <TableCell key={name} style={{ padding: 5 }} component="th" scope="row">
                  <Badge
                    style={{ backgroundColor: getMeterBgColor(name) }}
                    className={css(dynamicStyles(getMeterBgColor(name)).badge)}
                  >
                    {name}
                  </Badge>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {elastometersResultTableItems.map(propertyName => (
            <StyledTableRow key={propertyName}>
              <TableCell style={{ padding: 5 }} component="th" scope="row">
                <Translate text={propertyName} />
              </TableCell>

              {calculationValues?.map(
                (elastometerValues, inclinometerIndex) => (!elastometerValues) ? null : (
                  <UnitCell
                    key={`dynamometer-${inclinometerIndex}`}
                    propertyName={propertyName}
                    value={
                      propertyName in elastometerValues.commonValues ?
                        elastometerValues.commonValues[propertyName] :
                        propertyName in elastometerValues.results ?
                          elastometerValues.results[propertyName] :
                          null
                    }
                  />
                )
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

    </Grid>
  );
};


const PullingTestInclinometersResultsTable: FC<
  { calculationValues: (InclinometerCalculationDto | undefined)[] }
> = ({ calculationValues }) => {
  const { safetyCoeficientTable: { availableInclinometers } } = useTensileTest();

  const activeInclinometer = calculationValues.find(inclinometer => inclinometer);
  if (!activeInclinometer) {
    return null;
  }

  return (
    <Grid item className={css(styles.tensilTestCard)}>

      <Grid container justifyContent="space-between" style={{ padding: '5px 10px' }}>
        <Grid item>
          <Typography variant="h6" style={{ fontWeight: 700, margin: 10 }}>
            <Translate text="resultFor" />
            {'Inclinometers'}
          </Typography>
        </Grid>
      </Grid>


      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell />

            {calculationValues.map((inclinometer, index) => {
              const name = availableInclinometers[index];

              return (!inclinometer) ? null : (
                <TableCell key={name} style={{ padding: 5 }} component="th" scope="row">
                  <Badge
                    style={{ backgroundColor: getMeterBgColor(name) }}
                    className={css(dynamicStyles(getMeterBgColor(name)).badge)}
                  >
                    {name}
                  </Badge>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {inclinometersResultTableItems.map(prop => (
            <StyledTableRow key={prop}>
              <TableCell style={{ padding: 5 }} component="th" scope="row">
                <Translate text={prop} />
              </TableCell>

              {calculationValues?.map(
                (inclinometerValues, inclinometerIndex) => (!inclinometerValues) ? null : (
                  <UnitCell
                    key={`dynamometer-${inclinometerIndex}`}
                    propertyName={prop}
                    value={inclinometerValues[prop]}
                  />
                )
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

    </Grid>
  );
};
